import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "../src/store/store";
import "./index.css";
import App from "./App";
import { ParallaxProvider } from "react-scroll-parallax";
import * as serviceWorker from "./serviceWorker";
import { hydrate, render } from "react-dom";

ReactDOM.render(
    <React.StrictMode>
        <ParallaxProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </ParallaxProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//快取機制
// serviceWorker.register();
