import React, { useState, useEffect, useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { detectMob, checkLogin_and_redirect } from "../utils/Functions";
import Sticky from "react-stickynode";
import { Form } from "react-bootstrap";
import "./css/NavBar.scss";
import FontSizeChanger from "react-font-size-changer";
import { isWebpSupported } from "react-image-webp/dist/utils";

import CheckLoginAndRedirect from "../Components/CheckLoginAndRedirect";
import { AuthContext } from "../utils/Context";
import greenLogo from "../images1/green-logo.webp";
import fontIcon from "../images1/about/u70.png";
import homeIcon from "../images1/about/u84.png";
import aboutIcon from "../images1/navBar/about.png";
import activityIcon from "../images1/navBar/activity.png";
import greenIcon from "../images1/navBar/green.png";
import verifyIcon from "../images1/navBar/verify.png";
import dataIcon from "../images1/navBar/data.png";
import greenManNav from "../images1/greenMan/greenManNav.png";
import netZero from "../images1/navBar/netZero.png";

// 節慶專用圖片
import event_Logo from "../images1/navbar-BGC-footer/event_logo.svg";

function NavBar() {
    //google站內搜尋
    useEffect(() => {
        const includeScript = () => {
            var cx = "7a502e0e8f34030f9";
            var gcse = document.createElement("script");
            gcse.type = "text/javascript";
            gcse.async = true;
            gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(gcse, s);
        };
        includeScript();
    }, []);

    const keyPress = (e) => {
        if (e.charCode === 13) {
            e.preventDefault();
            window.location.replace(`/search?q=${e.target.value}`);
            return false;
        }
    };

    const [dropDown, setDropDown] = useState(true);
    const [keyWord, setKeyWord] = useState("");
    const [gotoUrl, setGotoUrl] = useState("");
    // const [show, setShow] = useState(true);

    const showMemberBtn = sessionStorage.getItem("userGuid");
    const { contextMemberInfo } = useContext(AuthContext);
    const [centerFocus, setCenterFocus] = useState(false);

    // const [currentTime, setCurrentTime] = useState(new Date()); // 當前時間
    // const [showEventLogo, setShowEventLogo] = useState(false);

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY && currentScrollY > 100) {
          
            setShowNavbar(false);
        } else {
           
            setShowNavbar(true);
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    // begin::活動背景LOGO更換計時器
    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setCurrentTime(new Date());
    //     }, 1000); // 更新當前時間，可依需求調整間隔

    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);

    // useEffect(() => {
    //     const isEventTime =
    //         currentTime >= new Date("2024-09-12T00:00:01") &&
    //         currentTime <= new Date("2024-11-05T23:23:59");
    //     setShowEventLogo(isEventTime);
    // }, [currentTime]);
    // end::活動背景LOGO更換計時器

    const headPic = contextMemberInfo?.headPic;

    function setUp() {
        var ui_w = 375;
        var clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
        var html_ = document.getElementsByTagName("html")[0];
        html_.style.fontSize = (clientWidth / ui_w) * 5 + "px";
    }

    function setDown() {
        var ui_w = 375;
        var clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
        var html_ = document.getElementsByTagName("html")[0];
        html_.style.fontSize = (clientWidth / ui_w) * 4 + "px";
    }

    useEffect(() => {
        const fontUp = document.querySelector(".fontUp");
        const fontDown = document.querySelector(".fontDown");
        fontUp.addEventListener("keydown", function () {
            setUp();
        });
        fontDown.addEventListener("keydown", function () {
            setDown();
        });
    });
    //  關閉遊戲活動
    // const date = new Date();
    // useEffect(() => {
    //     if (formatDateTime(date) >= "2022/12/27 00:00") {
    //         setShow(false);
    //     }
    // }, []);

    return (
        <>
            <Sticky innerZ={100} enabled={true} top={0} bottomBoundary={0}>
                <div className="navbar-placeholder"></div>
                <div className={`navbar-scroll ${showNavbar ? "visible" : "hidden"}`}>
                    {gotoUrl && <CheckLoginAndRedirect key={gotoUrl} gotoUrl={gotoUrl} />}
                    <nav className="nav-top">
                        <div className="top-dflex">
                            {centerFocus ? (
                                <a
                                    className="centerFocus"
                                    href="#c"
                                    title="按Enter至主內容區"
                                    accessKey="c"
                                    tabIndex={0}
                                    onBlur={() => setCenterFocus(false)}
                                >
                                    按Enter至主內容區
                                </a>
                            ) : (
                                <a
                                    className="skip-nav-c"
                                    id="skip-nav-c"
                                    href="#c"
                                    title="中央內容區塊"
                                    accessKey="c"
                                    tabIndex={0}
                                    onFocus={() => setCenterFocus(true)}
                                >
                                    :::
                                </a>
                            )}

                            <a className="skip-nav" href="#" title="上方導覽連結區" accessKey="u">
                                :::
                            </a>
                            <h1 className="green-logo">
                                <Link as={Link} to="/">
                                    {/* {showEventLogo ? (
                                        <img src={event_Logo} alt="淨零綠生活LOGO" />
                                    ) : (
                                        <img src={greenLogo} alt="淨零綠生活LOGO" />
                                    )} */}
                                    <img src={greenLogo} alt="淨零綠生活LOGO" />
                                </Link>
                            </h1>

                            <form className="form-inline-ori">
                                <ul className="nav-top-ul font">
                                    <a className="skip-nav" href="#" title="網站搜尋" accessKey="s">
                                        :::
                                    </a>

                                    <div id="myDropdown" className="searchDropdown-content show">
                                        <Form.Control
                                            onKeyPress={(e) => keyPress(e)}
                                            type="text"
                                            title="站內搜尋"
                                            className="search-input"
                                            placeholder="綠色旅遊、綠色辦公..."
                                            onChange={(e) =>
                                                setKeyWord(
                                                    e.target.value === "" ? " " : e.target.value
                                                )
                                            }
                                        />
                                        <Link
                                            className="searchBtn-kn"
                                            to={`/search?q=${keyWord}`}
                                            id="searchBtn"
                                            title="searchBtn"
                                            role={"Button"}
                                        >
                                            <i
                                                className="fas fa-search"
                                                title="站內搜尋"
                                                desc="站內搜尋"
                                            ></i>
                                        </Link>
                                        <div className="pop-keyWord">
                                            熱門關鍵字：
                                            <a
                                                href="/search?q=%E7%B6%A0%E8%89%B2%E6%97%85%E9%81%8A"
                                                title="綠色旅遊熱門連結"
                                            >
                                                綠色旅遊、
                                            </a>
                                            <a
                                                href="/search?q=%E7%92%B0%E4%BF%9D%E9%A4%90%E5%BB%B3"
                                                title="環保餐廳熱門連結"
                                            >
                                                環保餐廳、
                                            </a>
                                            <a
                                                href="/search?q=%E7%B6%A0%E8%89%B2%E8%BE%A6%E5%85%AC"
                                                title="綠色辦公熱門連結"
                                            >
                                                綠色辦公
                                            </a>
                                        </div>
                                    </div>
                                    {/* </div> */}

                                    <div className="font-changer ">
                                        <li className="d-block fontChanger-left">
                                            <img src={fontIcon} alt="字級圖示"></img>
                                            <p className="fontTopText" title="網站導覽">
                                                字級
                                            </p>
                                        </li>
                                        <FontSizeChanger
                                            targets={["#target"]}
                                            onChange={(element, newValue, oldValue) => {}}
                                            options={{
                                                stepSize: 1,
                                                range: 1,
                                            }}
                                            customButtons={{
                                                up: (
                                                    <span
                                                        className="fontUp"
                                                        style={{
                                                            fontSize: "28px",
                                                            cursor: "pointer",
                                                        }}
                                                        tabIndex={0}
                                                    >
                                                        大
                                                    </span>
                                                ),
                                                down: (
                                                    <span
                                                        className="fontDown"
                                                        style={{
                                                            fontSize: "28px",
                                                            cursor: "pointer",
                                                        }}
                                                        tabIndex={0}
                                                    >
                                                        小
                                                    </span>
                                                ),
                                                style: {
                                                    backgroundColor: "transparent",
                                                    color: "grey",
                                                    border: "none",
                                                    WebkitBoxSizing: "border-box",
                                                    WebkitBorderRadius: "5px",
                                                    width: "30px",
                                                },
                                                buttonsMargin: 10,
                                            }}
                                        />
                                    </div>
                                    <Link as={Link} to="/about" id="goMain">
                                        <li className="d-block nav-top-li border-left">
                                            <img src={homeIcon} alt=""></img>
                                            <p title="回首頁">回首頁</p>
                                        </li>
                                    </Link>
                                    <Link as={Link} to="/siteNavigator">
                                        <li className="d-block nav-top-li">
                                            <i
                                                className="far fa-compass"
                                                aria-hidden="true"
                                                alt=""
                                            ></i>
                                            <p title="網站導覽">網站導覽</p>
                                        </li>
                                    </Link>

                                    <nav className="navbar navbar-expand-lg greenAction-navbar">
                                        <div
                                            className="collapse navbar-collapse justify-content-center"
                                            id="navbarSupportedContent"
                                        >
                                            <ul className="navbar-nav ">
                                                <li className="d-block nav-top-li soon-btn font action-zindex">
                                                    <Link
                                                        to={"/"}
                                                        className="nav-link p-0"
                                                        role="button"
                                                        data-toggle="dropdown"
                                                    >
                                                        <img
                                                            className="nav-island"
                                                            src={greenManNav}
                                                            alt=""
                                                        />
                                                        <p className="island_p">
                                                            <span>我的綠行動</span>
                                                        </p>
                                                    </Link>

                                                    <div className="dropdown-menu dropdown-menu-ori nav-item-drop ">
                                                        <Link
                                                            className="dropdown-item nav-aniBtn"
                                                            to="/daily"
                                                        >
                                                            綠生活成果榜
                                                        </Link>
                                                        <div
                                                            className="dropdown-item nav-aniBtn"
                                                            onClick={() =>
                                                                checkLogin_and_redirect(
                                                                    setGotoUrl,
                                                                    "/member/point_mission?type=1"
                                                                )
                                                            }
                                                        >
                                                            綠生活任務
                                                        </div>
                                                        <Link
                                                            className="dropdown-item nav-aniBtn"
                                                            to="/greenGame"
                                                        >
                                                            綠寶家族的綠活群島生活
                                                        </Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </ul>
                                <div className="d-flex justify-content-center nav-top-ul">
                                    <Link
                                        to={
                                            showMemberBtn
                                                ? "/member/memberCenter"
                                                : "/AdvancingPage"
                                        }
                                    >
                                        {headPic ? (
                                            <div className="d-block nav-top-li-grey avetar">
                                                <img
                                                    src={headPic}
                                                    className="clip-headpic-nav"
                                                    alt=""
                                                    title="會員頭像"
                                                />
                                            </div>
                                        ) : (
                                            <li
                                                className="d-block nav-top-li-grey avetar"
                                                id="avetar"
                                            >
                                                <i
                                                    className="fas fa-user-circle"
                                                    aria-hidden="true"
                                                    title="登入圖示"
                                                    alt=""
                                                ></i>
                                            </li>
                                        )}

                                        <div className="avatar-note">
                                            {showMemberBtn ? "會員專區【已登入】" : "登入"}
                                        </div>
                                    </Link>
                                    <>
                                        <Link to="/en_About" className="d-flex align-items-center">
                                            <li className="d-block nav-top-li border-right-0">
                                                <button
                                                    style={{
                                                        backgroundColor: "#6CB15E",
                                                        color: "#fff",
                                                        border: "none",
                                                        borderRadius: "10px",
                                                        padding: "8px 14px",
                                                        margin: "0px 5px 0 20px",
                                                        fontSize: "calc(10px + 1vw)",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    EN
                                                </button>
                                            </li>
                                        </Link>
                                    </>
                                </div>
                            </form>
                        </div>
                    </nav>

                    {/* 主功能選單 */}

                    <ul className={dropDown ? "topnav-ori" : "topnav-ori responsive mobile-nav"}>
                        <a
                            href="#"
                            className="icon icon-cancel"
                            title="cancel"
                            id="cancel"
                            onClick={() => setDropDown(true)}
                        >
                            <i
                                className="fas fa-times"
                                aria-hidden="true"
                                title="關閉圖示"
                                desc="關閉圖示"
                            ></i>
                        </a>
                        <div className={dropDown ? "drop-first-layer" : "drop-first-layer show"}>
                            <Link
                                to={showMemberBtn ? "/member/memberCenter" : "/login"}
                                title="前往登入"
                            >
                                <li className="d-block nav-top-li-grey avetar" id="avetar">
                                    <i
                                        className="fas fa-user-circle"
                                        aria-hidden="true"
                                        title="bar"
                                        alt=""
                                    ></i>
                                </li>
                            </Link>
                        </div>

                        <li className="dropdown-drop" tabIndex={0}>
                            <Link to="/about">
                                <div className="dropbtn">
                                    <img className="nav-img" src={aboutIcon} alt="關於綠生活圖示" />
                                    <div>關於綠生活</div>
                                </div>
                            </Link>

                            {(!dropDown || !detectMob()) && (
                                <ul className="dropdown-menu-ori nav-item-drop" tabIndex={0}>
                                    <li>
                                        <Link
                                            as={Link}
                                            className="nav-aniBtn"
                                            to="/about#page1"
                                            tabIndex={0}
                                        >
                                            最新消息
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            className="nav-aniBtn"
                                            to="/about#intro"
                                            tabIndex={0}
                                        >
                                            了解綠生活
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            className="nav-aniBtn"
                                            to="/about#anchor3"
                                            tabIndex={0}
                                        >
                                            綠生活焦點照片
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            className="nav-aniBtn"
                                            to="/about#anchor4"
                                            tabIndex={0}
                                        >
                                            常用查詢
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <li
                            className="dropdown-drop"
                            onClick={() => setDropDown(true)}
                            tabIndex={0}
                        >
                            <Link
                                className="nav-link dropdown-toggle dropbtn"
                                to="/searchEvent"
                                role="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                as={Link}
                            >
                                <img
                                    className="nav-img"
                                    src={netZero}
                                    alt="淨零綠生活行動指引專區圖示"
                                />
                                淨零綠生活行動指引
                            </Link>
                            {(!dropDown || !detectMob()) && (
                                <ul className="dropdown-menu-ori nav-item-drop">
                                    <li>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/NetZeroTheme"
                                            as={Link}
                                        >
                                            主題故事
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/NetZeroIndex"
                                            as={Link}
                                        >
                                            查看指引全文
                                        </Link>
                                    </li>
                                    <li>
                                        <div
                                            className="dropdown-item nav-aniBtn"
                                            onClick={() =>
                                                checkLogin_and_redirect(
                                                    setGotoUrl,
                                                    "/NetZeroIndex/NetZeroSurveyForm"
                                                )
                                            }
                                            as={Link}
                                        >
                                            填寫自評表
                                        </div>
                                    </li>
                                    <li>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/gameEvent"
                                            as={Link}
                                        >
                                            兌獎專頁
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <li
                            className="dropdown-drop"
                            onClick={() => setDropDown(true)}
                            tabIndex={0}
                        >
                            <Link as={Link} to="/searchEvent">
                                <div className="dropbtn">
                                    <img
                                        className="nav-img"
                                        src={activityIcon}
                                        alt="活動專區圖示"
                                    />
                                    <div>活動專區</div>
                                </div>
                            </Link>
                            {(!dropDown || !detectMob()) && (
                                <ul className="dropdown-menu-ori nav-item-drop">
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            className="nav-aniBtn"
                                            to="/conferenceEvent"
                                        >
                                            淨零綠生活社會溝通
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        <li
                            className="dropdown-drop"
                            onClick={() => setDropDown(true)}
                            tabIndex={0}
                        >
                            <Link as={Link} to="/categories">
                                <div className="dropbtn">
                                    <img className="nav-img" src={greenIcon} alt="綠生活選擇圖示" />
                                    <div>綠生活選擇</div>
                                </div>
                            </Link>
                        </li>

                        <li className="dropdown-drop" onClick={() => setDropDown(true)}>
                            <Link
                                as={Link}
                                to="/knowledge?page=1&type=0&theme=&n=全部"
                                onClick={() => setDropDown(true)}
                            >
                                <div className="dropbtn">
                                    <img className="nav-img" src={activityIcon} alt="" />
                                    知識綠
                                </div>
                            </Link>
                        </li>

                        <li className="dropdown-drop" tabIndex={0}>
                            <div className="dropbtn dropbtn-focus verify" tabIndex={0}>
                                <img className="nav-img" src={verifyIcon} alt="標章及採購圖示" />
                                <div>標章及採購</div>
                            </div>
                            {(!dropDown || !detectMob()) && (
                                <ul className="dropdown-menu-ori nav-item-drop none" tabIndex={0}>
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            to="/greenLabel"
                                            className="nav-aniBtn"
                                            tabIndex={0}
                                        >
                                            環保標章
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            to="/greenLabelApplySupport"
                                            className="nav-aniBtn"
                                            tabIndex={0}
                                        >
                                            標章申請輔導
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            as={Link}
                                            onClick={() => setDropDown(true)}
                                            to="/greenPurChase"
                                            className="nav-aniBtn"
                                            tabIndex={0}
                                        >
                                            綠色採購
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <li className="dropdown-drop" onClick={() => setDropDown(true)}>
                            <Link
                                as={Link}
                                to="/download/promote?type=1"
                                onClick={() => setDropDown(true)}
                            >
                                <div className="dropbtn verify">
                                    <img className="nav-img" src={dataIcon} alt="下載專區圖示" />
                                    <div>下載專區</div>
                                </div>
                            </Link>
                        </li>

                        {detectMob() && (
                            <div
                                className={
                                    dropDown || !detectMob() ? "green-display" : "dropdown-drop"
                                }
                            >
                                <button className="dropbtn">
                                    <img className="nav-img" src={greenManNav} alt="我的綠行動" />
                                    <div>我的綠行動</div>
                                </button>
                                {(!dropDown || !detectMob()) && (
                                    <ul className="dropdown-menu-ori nav-item-drop none">
                                        <li>
                                            <Link
                                                as={Link}
                                                onClick={() => setDropDown(true)}
                                                className="nav-aniBtn"
                                                to="/greenGame"
                                            >
                                                綠寶家族的綠活群島生活
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                as={Link}
                                                onClick={() => setDropDown(true)}
                                                to="/daily"
                                                className="nav-aniBtn"
                                            >
                                                綠生活成果榜
                                            </Link>
                                        </li>
                                        <li>
                                            <div
                                                onClick={() =>
                                                    checkLogin_and_redirect(
                                                        setGotoUrl,
                                                        "/member/point_mission?type=1"
                                                    )
                                                }
                                                className="nav-aniBtn"
                                            >
                                                綠生活任務
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        )}

                        <a
                            href="#"
                            className="icon icon-bar"
                            title="bar"
                            id="bar"
                            onClick={() => setDropDown(false)}
                        >
                            <i
                                className="fa fa-bars"
                                aria-hidden="true"
                                title="選單圖示"
                                desc="選單圖示"
                            ></i>
                        </a>

                        <div className={dropDown ? "drop-first-layer" : "drop-first-layer show"}>
                            <Link as={Link} to="/siteNavigator" onClick={() => setDropDown(true)}>
                                <li className="d-block nav-top-li siteNav" id="siteNav">
                                    <i
                                        className="far fa-compass"
                                        aria-hidden="true"
                                        title="網站導覽圖示"
                                        desc="網站導覽圖示"
                                    ></i>
                                    <p title="網站導覽">網站導覽</p>
                                </li>
                            </Link>

                            <div className="d-flex flex-column  m-auto">
                                <div className="d-flex">
                                    <Form.Control
                                        className="search-input"
                                        placeholder="綠色旅遊、綠色辦公..."
                                        title="站內搜尋"
                                        onBlur={(e) =>
                                            setKeyWord(e.target.value === "" ? " " : e.target.value)
                                        }
                                    />
                                    <Link
                                        className="searchBtn-kn-nav"
                                        to={`/search?q=${keyWord}`}
                                        id="searchBtn"
                                        title="searchBtn"
                                    >
                                        <i
                                            className="fas fa-search"
                                            title="站內搜尋圖示"
                                            desc="站內搜尋圖示"
                                        ></i>
                                    </Link>
                                </div>
                                <div className="pop-keyWord mt-2">
                                    熱門關鍵字：
                                    <a
                                        href="/search?q=%E7%B6%A0%E8%89%B2%E6%97%85%E9%81%8A"
                                        title="綠色旅遊熱門連結"
                                    >
                                        綠色旅遊、
                                    </a>
                                    <a
                                        href="/search?q=%E7%92%B0%E4%BF%9D%E9%A4%90%E5%BB%B3"
                                        title="環保餐廳熱門連結"
                                    >
                                        環保餐廳、
                                    </a>
                                    <a
                                        href="/search?q=%E7%B6%A0%E8%89%B2%E8%BE%A6%E5%85%AC"
                                        title="綠色辦公熱門連結"
                                    >
                                        綠色辦公
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ul>

                    <nav className="navbar navbar-expand-lg  main-navbar desktop-nav">
                        <div
                            className="collapse navbar-collapse justify-content-center offcanvas offcanvas-end"
                            id="navbarSupportedContent"
                        >
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle dropbtn"
                                        to="/about"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={aboutIcon}
                                            alt="關於綠生活圖示"
                                        />
                                        關於綠生活
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-ori nav-item-drop">
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/about#page1"
                                        >
                                            最新消息
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/about#intro"
                                        >
                                            了解綠生活
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/about#anchor3"
                                        >
                                            綠生活焦點照片
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/about#anchor4"
                                        >
                                            常用查詢
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle dropbtn"
                                        to="/searchEvent"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={netZero}
                                            alt="淨零綠生活行動指引專區圖示"
                                        />
                                        淨零綠生活行動指引
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-ori nav-item-drop">
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/NetZeroTheme"
                                        >
                                            主題故事
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/NetZeroIndex"
                                        >
                                            查看指引全文
                                        </Link>
                                        <div
                                            className="dropdown-item nav-aniBtn"
                                            onClick={() =>
                                                checkLogin_and_redirect(
                                                    setGotoUrl,
                                                    "/NetZeroIndex/NetZeroSurveyForm"
                                                )
                                            }
                                        >
                                            填寫自評表
                                        </div>
                                        <Link className="dropdown-item nav-aniBtn" to="/gameEvent">
                                            兌獎專頁
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle dropbtn"
                                        to="/searchEvent"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={activityIcon}
                                            alt="活動專區圖示"
                                        />
                                        活動專區
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-ori nav-item-drop">
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/searchEvent"
                                        >
                                            活動查詢
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/conferenceEvent"
                                        >
                                            淨零綠生活社會溝通
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropbtn"
                                        as={Link}
                                        to="/categories"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={greenIcon}
                                            alt="綠生活選擇圖示"
                                        />
                                        綠生活選擇
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropbtn"
                                        as={Link}
                                        to="/knowledge?page=1&type=0&theme=&n=全部"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={activityIcon}
                                            alt="知識綠圖示"
                                        />
                                        知識綠
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle dropbtn"
                                        to="/about"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={verifyIcon}
                                            alt="標章及採購圖示"
                                        />
                                        標章及採購
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-ori nav-item-drop">
                                        <Link className="dropdown-item nav-aniBtn" to="/greenLabel">
                                            環保標章
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/greenLabelApplySupport"
                                        >
                                            標章申請輔導
                                        </Link>
                                        <Link
                                            className="dropdown-item nav-aniBtn"
                                            to="/greenPurChase"
                                        >
                                            綠色採購
                                        </Link>
                                    </div>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropbtn"
                                        as={Link}
                                        to="/download/promote?type=1"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <img
                                            className="nav-img"
                                            src={dataIcon}
                                            alt="下載專區圖示"
                                        />
                                        下載專區
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </Sticky>
            <div
                className={dropDown ? "aside-backdrop" : "aside-backdrop show"}
                onClick={() => setDropDown(true)}
            ></div>
        </>
    );
}

export default NavBar;
